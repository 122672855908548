

.amigo-RequestParticipantPathwayTimeline ul{
    padding:0;
    list-style-type: none;
    border:1px solid transparent;
  }
  .amigo-RequestParticipantPathwayTimeline ul:before, .amigo-RequestParticipantPathwayTimeline ul:after{
    content:"";
    display:table;
  }
  .amigo-RequestParticipantPathwayTimeline ul:after{
    clear:both;
  }
  .amigo-RequestParticipantPathwayTimeline ul li {
      display: inline-block;
      width: 25%;
      float: left;
      text-align: center;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      font-family: sans-serif;
  }
  .amigo-RequestParticipantPathwayTimeline ul li.long { width: 33%; }
  .amigo-RequestParticipantPathwayTimeline ul li.active {
      background: forestgreen;
      color: #fff;
  }
  .amigo-RequestParticipantPathwayTimeline ul li.disabled {
    background: darkred;
    color: #fff;
}
  .amigo-RequestParticipantPathwayTimeline ul li a {
      padding: 0 12px;
      height: 40px;
      line-height: 20px;
      position: relative;
      display: block;
  }
  .amigo-RequestParticipantPathwayTimeline ul li.long a {
      line-height: 40px;
  }
  .amigo-RequestParticipantPathwayTimeline ul li.active a:before,.amigo-RequestParticipantPathwayTimeline ul li.active a:after,
  .amigo-RequestParticipantPathwayTimeline ul li.disabled a:before,.amigo-RequestParticipantPathwayTimeline ul li.disabled a:after{
      content: "";
      position: absolute;
      border-style: solid;
      border-color: transparent transparent transparent rgb(243, 242, 241);
      border-width: 20px;
      left: 0px;
  }
  .amigo-RequestParticipantPathwayTimeline ul li.active a:after {
      content: "";
      right: -40px;
      top: 0;
      left: auto;
      border-color: transparent transparent transparent forestgreen;
  }
  .amigo-RequestParticipantPathwayTimeline ul li.disabled a:after {
      content: "";
      right: -40px;
      top: 0;
      left: auto;
      border-color: transparent transparent transparent darkred;
  }
  .amigo-RequestParticipantPathwayTimeline ul li.active a, .amigo-RequestParticipantPathwayTimeline ul li.disabled a {
      padding-left: 30px;
  }