body {
  direction: ltr;
  width: 100%;
  overflow-y: hidden;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  margin: 0 !important;
}

/* fabric overrides */
.ms-Fabric .ms-GridView-Complete .ms-Viewport {
  max-width: 100% !important;
  width: calc(-200px + 100vw);
}

.ms-Fabric .AssistedGrid .ms-Viewport {
  max-width: 100% !important;
  width: 95%;
}

@media (max-width: 767px) {
  .ms-Fabric .ms-GridView-Complete .ms-Viewport {
    max-width: 100% !important;
    width: calc(100vw) !important;
  }

  .ms-Viewport {
    max-width: 100% !important;
    width: calc(100vw);
  }
}

.ms-ScrollablePane--contentContainer {
  overflow-x: hidden;
  width: 100%;
}

.stickyBelowItems-68 {
  z-index: -1;
}

.ms-GridViewCard .rccs {
  transform: scale(0.85) !important;
}

.ms-Fabric .ms-Pivot-link .ms-Pivot-PivotItem-Navigate .ms-Pivot-icon i,
.ms-Fabric .ms-Pivot i[data-icon-name="NavigateBack"],
.ms-Fabric .ms-Pivot i[data-icon-name="NavigateBack"]:hover {
  font-size: 16px;
  position: absolute;
  top: 2.5px;
  left: 3px;
}

/* ------------------------------ */

.selection {
  height: calc(100vh - 16.5em);
  overflow-x: auto;
}

.selection .selection-item {
  display: flex;
  padding: 0.5em;
}

.selection .selection-item .name {
  margin-left: 1em;
}

.ms-Stack.ms-Card.ListViewCard {
  min-width: unset !important;
  float: left;
  margin: 10px;
}

.clear-both {
  clear: both;
}

.ms-Fabric .MessageFolderContainer {
  min-height: calc(-195px + 100vh);
  overflow: "auto";
}

.ms-Checkbox-required .ms-Checkbox-label::after {
  content: " *";
  color: rgb(164, 38, 44);
  padding-left: 6px;
  padding-right: 12px;
}

.ms-Fabric .body.onmaintenance .MessageFolderContainer {
  min-height: calc(-236px + 100vh);
  overflow: "auto";
}

.ms-Fabric .ms-AppBody .MessageFolderScroll {
  max-height: calc(-195px + 100vh) !important;
  overflow: "auto";
}

.ms-Fabric .body.onmaintenance .MessageFolderScroll {
  max-height: calc(-236px + 100vh) !important;
  overflow: "auto";
}

.ms-AppBody .content .ms-Card .ms-Card-Image,
.ms-AppBody .content .ms-Card .ms-Card-Image img {
  width: 16px;
  height: 16px;
}

.ms-Fabric .FullCardSection {
  width: 100%;
  min-width: 140px;
}

.nvx-errorMessage {
  animation-name: css-1, css-14;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  font-weight: 400;
  color: rgb(164, 38, 44);
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-top: 5px;
  display: flex;
  align-items: center;
}

.ms-Card-bottom-container {
  float: left;
  line-height: 16px;
  margin-left: 0.7rem;
  min-width: 148px;
}

@media (max-width: 759px) {
  .ms-Card-bottom-container {
    min-width: calc(-100px + 100vw);
  }
}

@media (max-width: 1024px) {
  .ms-Card-bottom-container {
    min-width: 167px;
  }
}

@media (max-width: 1224px) {
  .ms-Card-bottom-container {
    min-width: 142px;
  }
}

.ms-Fabric .content h1.MailSubject {
  padding: 6px 0px 0px 0px;
}

.ms-Fabric .ms-Persona.ms-Persona--size72 {
  padding: 5.5rem 1rem;
  margin-bottom: 2rem;
}
.ms-Fabric .ms-Persona.ms-Persona--size72.ms-Number-Persona {
  padding: 4rem 2rem;
  margin-bottom: 2rem;
}

.ms-Fabric .ms-Card-bottom-container {
  float: left;
  margin-left: 1rem;
  min-width: 148px;
  max-width: 214px;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
  .ms-Fabric .ms-Card-bottom-container {
    max-width: 180px !important;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .ms-Fabric .ms-Card-bottom-container {
    max-width: 160px !important;
  }
}

.ms-Fabric .ms-List.ms-GroupedList-Tiles .ms-List-cell {
  min-height: unset !important;
  float: left !important;
}

.ms-GroupedList .ms-List.ms-GroupedList-Tiles .ms-List-cell {
  min-height: unset !important;
  float: left !important;
}

.amigo-RequestDetailPage
  .ms-GroupedList
  .ms-List.ms-GroupedList-Tiles
  .ms-List-cell {
  min-height: unset !important;
  float: left !important;
}

.ms-Fabric .ms-GroupedList .ms-List .ms-List-page {
  clear: both !important;
}

.request-confirmed {
  color: green;
  width: 32px;
  height: 32px;
}

.request-closed {
  color: black;
  width: 32px;
  height: 32px;
}

.request-waiting {
  color: yellow;
  width: 32px;
  height: 32px;
}

.message-summary {
  position: relative;
  cursor: pointer;
  border-top: 1px solid rgb(243, 242, 241);
  border-right: 1px solid rgb(243, 242, 241);
}

.message-summary.odd {
  background-color: rgb(243, 242, 241);
}

.ms-List-cell:last-child {
  border-bottom: 1px solid rgb(243, 242, 241);
}

.message-summary .selected-message {
  background-color: rgb(0, 120, 212, 0.1);
}

.message-actions {
  position: absolute;
  right: 0;
  top: 0;
}

span.message-datetime {
  font-weight: 600;
  padding: 0;
  line-height: 2rem;
}

.content h1 {
  padding-left: 6px;
}

.ms-Fabric .ms-Persona.ms-Persona--size72 {
  padding: 2.8rem 0;

  margin-bottom: 0;
  width: 100%;
}

.ms-Fabric .ms-Persona.ms-Persona--size72 .ms-Persona-coin {
  transform: scale(0.8);
}

@media (max-width: 767px) {
  .ms-Fabric .StackItem-Persona {
    width: 100%;
    max-width: 500px;
  }

  .ms-Fabric .ms-Persona-tertiaryText {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .ms-Fabric .StackItem-Persona {
    width: 100%;
    max-width: 500px;
  }

  .ms-Fabric .ms-Persona-tertiaryText {
    width: 500px;
  }
}

@media (min-width: 1224px) {
  .ms-Fabric .StackItem-Persona {
    width: 100%;
    max-width: 450px;
  }

  .ms-Fabric .ms-Persona-tertiaryText {
    width: 100%;
  }
}

.ms-Fabric .ms-Persona.ms-Persona--size72 .ms-Persona-primaryText {
  font-weight: bold;
}

.ms-Fabric
  .ms-Persona.ms-Persona--size72
  .ms-Persona-secondaryText
  .ms-TooltipHost {
  font-size: 16px;
}

.ms-Fabric .ms-Persona-details {
  padding-right: 5px;
}

.ms-TextField.is-required
  .ms-TextField-wrapper
  .ms-TextField-fieldGroup::before {
  background-color: transparent !important;
  visibility: hidden !important;
}

.ms-Panel-content {
  height: auto !important;
}

.MessageCommandBar .ms-CommandBar {
  padding-left: 0px;
  padding-right: 0px;
}

.ms-Fabric
  .Message-Persona-Normal
  .ms-Persona.ms-Persona--size72
  .ms-Persona-primaryText {
  font-weight: 400;
}

.IndentPivot .ms-Pivot {
  margin-left: 20px;
}

.EnterpriseBackground {
  min-height: 20vh;
  width: 100%;
  height: 100%;
  z-index: -1;
  content: "";
  padding-top: 20px;
}

@media (max-width: 767px) {
  .EnterpriseBackground {
    min-height: 160px;
  }
}

.DocumentCategory {
  border-radius: 72px;
  color: white;
  background-color: orange;
  padding: 20px;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-size: 72px;
}

.ms-DetailsRow-fields {
  width: 100%;
}

.ms-Fabric .ms-DetailsRow-fields .DocumentName {
  width: 100% !important;
}
.ms-Fabric .ms-DetailsRow-fields .DocumentSize {
  width: 10% !important;
}
.ms-Fabric .ms-DetailsRow-fields .DocumentDownload {
  width: 10% !important;
  text-align: right;
  margin-right: 4px;
}

.DeleteDialog .ms-Dialog-content .ms-Dialog-header {
  display: none;
}

.DeleteDialog .ms-Dialog-content .ms-Dialog-inner {
  padding: 0 10px 10px 0;
}

.ms-Fabric .ms-List-cell:last-child {
  border-bottom: 0px !important;
}

@media (max-width: 767px) {
  .ms-Fabric .ms-DetailsRow-fields .DocumentName {
    width: 250px !important;
  }
  .ms-Fabric .ms-DetailsRow-fields .DocumentSize {
    width: 20% !important;
  }
  .ms-Fabric .ms-DetailsRow-fields .DocumentDownload {
    width: 20% !important;
  }
}

.NavigateBack .ms-Button-label {
  font-weight: bold !important;
}

.WhitePersona .ms-Persona .ms-Persona-primaryText:hover {
  color: white !important;
}

.amigo-DocumentCategories .ms-List .ms-List-cell {
  min-height: unset !important;
}

/* waiting animation */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.nvx-loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #3498db;
  -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

.nvx-loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e74c3c;
  -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

.nvx-loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #f9c922;
  -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}

.inlineFlex-ChoiceGroup .ms-ChoiceField {
  display: inline-block;
}

.itemMargin-ChoiceGroup .ms-ChoiceFieldLabel {
  margin-right: 10px;
}

.verticalCenterQuestion-Label {
  display: 'flex';
  align-items: 'center';
  margin-right: '10px';
  justify-content: 'center';
}

.toggleVerticalCenter {
  margin-top: 5px;
}